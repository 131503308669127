@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.under-construction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background: #121212;
    color: #ffffff;
    font-family: 'Orbitron', sans-serif;
    overflow: hidden;
}

h1 {
    font-size: 2.5rem;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: glitch 1.5s infinite;
}

p {
    font-size: 1.2rem;
    opacity: 0.8;
    margin-bottom: 2rem;
}

/* Cool Loading Bar */
.loading-bar {
    width: 150px;
    height: 4px;
    background: linear-gradient(90deg, #ff00bf, #00eaff);
    border-radius: 2px;
    animation: loading 2s infinite linear;
}

/* Animations */
/*@keyframes glitch {*/
/*    0% {*/
/*        text-shadow: 2px 2px #ff00bf;*/
/*    }*/
/*    50% {*/
/*        text-shadow: -2px -2px #00eaff;*/
/*    }*/
/*    100% {*/
/*        text-shadow: 2px 2px #ff00bf;*/
/*    }*/
/*}*/

@keyframes loading {
    0% {
        transform: translateX(-50px);
        opacity: 0.2;
    }
    50% {
        transform: translateX(50px);
        opacity: 1;
    }
    100% {
        transform: translateX(-50px);
        opacity: 0.2;
    }
}
